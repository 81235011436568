import * as prismicTypes from '@prismicio/types'
import * as prismicH from '@prismicio/helpers'

import {
  STATUS,
  TYPES,
  MARKET_ARGUMENT,
  MARKET_TYPES,
  CONTENT_HIDDEN_LIST,
  IMAGE_FORMAT,
  MARKET_ARGUMENT_TAG,
} from '../../const/index'

/* eslint-disable camelcase */
/** Raison : Obligé de matcher avec l'API de Prismic qui a été définie comme ça */
interface ThirdVariantDocument {
  name?: string
  desc?: any[]
  image?: prismicTypes.ImageField | null
  variant_shopify_id?: string
}

interface matrixLine {
  number_per_line: number
}

export enum PRODUCT_TEXT_ALIGN {
  LEFT = 'Gauche',
  CENTER = 'Centre',
  RIGHT = 'Droite',
}
export interface IProductDocument
  extends prismicTypes.PrismicDocument<Record<string, any>, string, string> {
  data: {
    product_name?: prismicTypes.TitleField | null
    product_type?: TYPES
    market_product_type?: MARKET_TYPES
    shopify_id?: string | null
    status?: STATUS
    navbar_hidden?: boolean
    stock_initial?: number | null
    image?: prismicTypes.ImageField | null
    image_cdn?: prismicTypes.ImageField | null
    is_top_of_image_light?: boolean
    image_eshop?: prismicTypes.ImageField | null
    image_eshop_cdn?: prismicTypes.ImageField | null
    image_text_position?: boolean
    image_text_align?: PRODUCT_TEXT_ALIGN
    image_eshop_text_position?: boolean
    product_bar_image?: prismicTypes.ImageField | null
    context?: string
    on_home?: boolean

    tags?: any[]
    colors?: any[]
    description?: any[]
    bullets?: any[]
    punchline?: any[]
    third_variant_name?: string
    third_variant?: ThirdVariantDocument[]
    third_variant_effect?: boolean
    third_variant_label?: string

    startdate?: string | null
    enddate?: string | null

    teasing_startdate?: string | null
    teasing_link?: any

    average_rating?: string | null
    testimonial_number?: string | null
    highlight_size_guide?: boolean | null

    button_label?: string | null
    marketing?: MARKET_ARGUMENT
    marketing_message?: []
    marketing_tag?: MARKET_ARGUMENT_TAG
    content_hidden?: CONTENT_HIDDEN_LIST

    product_as_preco_stock?: prismicTypes.LinkField
    linked_products?: any[]
    inventory_links?: any[]

    ref_co_matrix?: any
    ref_co_matrix_mobile?: any
    order_and_render_of_ref_co?: any

    current_batch_date_end?: string
    next_delivery_date?: string

    meta_title?: any
    meta_description?: any

    // RETRO COMPATIBILITÉ
    agenda_image?: any | null
    eshop_image?: any | null
    // END RETRO COMPATIBILITÉ
  }
}

interface ProductMeta {
  title: string | null
  desc: string | null
}

interface Dates {
  start: Date | null
  end: Date | null
  teasing: Date | null
  currentBatchEnd?: Date
  nextDeliveryDate?: Date
}

enum GROUPFORMAT {
  ONE_PORTRAIT = 'portrait-and-both',
  TWO_PORTRAIT = 'both-portrait-and-full',
  ONE_SQUARE = 'square-and-both',
  DEFAULT = 'full-and-both',
}

interface IGalleryGroup {
  format: GROUPFORMAT
  images: prismicTypes.ImageField[]
}
interface IGallery {
  name: string
  images: prismicTypes.ImageField[]
  groups: IGalleryGroup[]
}

interface IThirdVariant {
  name?: string
  desc?: any[]
  image?: prismicTypes.ImageField | null
  variantShopifyId?: string
}

interface IMarketing {
  type: MARKET_ARGUMENT
  message: string
  tag: MARKET_ARGUMENT_TAG
}

export default class PrismicProduct {
  id: string = ''
  uid: string = ''
  name: string | null = null
  nameFull: prismicTypes.TitleField | null = null
  type: string = TYPES.NORMAL
  marketType: string = MARKET_TYPES.NORMAL
  shopifyId: number | null = null
  status: STATUS = STATUS.AGENDA
  navbarHidden: boolean = false
  stockInitial: number = 0
  image: any | null = null
  imageCdn: any | null = null
  isTopOfImageLight: boolean = false
  eshopImage: any | null = null
  eshopImageCdn: any | null = null
  imageTextPosition: boolean = true
  imageTextAlign: PRODUCT_TEXT_ALIGN = PRODUCT_TEXT_ALIGN.LEFT
  imageEshopTextPosition: boolean = true
  productBarImage: prismicTypes.ImageField | null = null
  context: string = ''
  onHome: boolean = false

  tags: any[] = []
  colors: any[] = []
  slices: any[] = []
  bullets: any[] = []
  punchline: any[] = []
  thirdDimensionName: string | null = null
  thirdDimensionLabel: string | null = null
  thirdDimensions: IThirdVariant[] = []
  thirdVariantEffect: boolean = false

  averageRating: number = 0
  numberOfTestimonials: number = 0
  highlightSizeGuide: boolean = false

  buttonLabel: string | null = null
  contentHidden: CONTENT_HIDDEN_LIST = CONTENT_HIDDEN_LIST.FALSE
  marketing: IMarketing = {
    type: MARKET_ARGUMENT.AUTOMATIQUE,
    message: '',
    tag: MARKET_ARGUMENT_TAG.AUTOMATIQUE,
  }

  productAsPrecoOrStock: prismicTypes.LinkField | null = null
  linkedProducts: any[] = []
  inventoryLinks: string[] = []

  refCoMatrix?: matrixLine[]
  refCoMatrixMobile?: matrixLine[]
  orderAndRenderOfRefCo?: any[]

  meta: ProductMeta = {
    title: null,
    desc: null,
  }

  dates: Dates = {
    start: null,
    end: null,
    teasing: null,
  }

  teasingLink: any = null

  constructor(data: IProductDocument) {
    this.id = data.id
    this.uid = data.uid || ''

    if (!data.data) {
      return
    }

    this.tags = data.tags || []
    if (prismicH.isFilled.title(data.data.product_name)) {
      this.name = prismicH.asText(data.data.product_name)
      this.nameFull = data.data.product_name
    }
    this.type = data.data.product_type || TYPES.NORMAL
    this.status = data.data.status || STATUS.AGENDA
    this.marketType = data.data.market_product_type || MARKET_TYPES.NORMAL
    this.shopifyId = data.data.shopify_id ? parseInt(data.data.shopify_id) : 0
    this.navbarHidden = data.data.navbar_hidden || false
    this.stockInitial = data.data.stock_initial || 0
    this.image = data.data.image || null
    this.imageCdn = data.data.image_cdn || null
    this.isTopOfImageLight = data.data.is_top_of_image_light || false
    this.eshopImage = data.data.image_eshop || null
    this.eshopImageCdn = data.data.image_eshop_cdn || null
    this.imageTextPosition = data.data.image_text_position ?? true
    this.imageTextAlign = data.data.image_text_align || PRODUCT_TEXT_ALIGN.LEFT
    this.imageEshopTextPosition = data.data.image_eshop_text_position ?? true
    this.productBarImage = data.data.product_bar_image || null
    this.context = data.data.context || ''
    this.onHome = data.data.on_home || false
    this.highlightSizeGuide = data.data.highlight_size_guide || false

    this.productAsPrecoOrStock = data.data.product_as_preco_stock || null

    this.linkedProducts = data.data.linked_products || []

    if (this.linkedProducts.length > 0) {
      this.linkedProducts = this.linkedProducts.filter((l) => l.label)
    }

    this.refCoMatrix = prismicH.isFilled.group(data.data.ref_co_matrix)
      ? (data.data.ref_co_matrix as unknown as matrixLine[])
      : [{ number_per_line: 3 }]
    this.refCoMatrixMobile = prismicH.isFilled.group(
      data.data.ref_co_matrix_mobile
    )
      ? (data.data.ref_co_matrix_mobile as unknown as matrixLine[])
      : [{ number_per_line: 2 }]
    this.orderAndRenderOfRefCo = prismicH.isFilled.group(
      data.data.order_and_render_of_ref_co
    )
      ? data.data.order_and_render_of_ref_co
      : []

    this.slices = data.data.description || []
    this.colors = data.data.colors || []
    this.bullets = data.data.bullets || []
    this.punchline = data.data.punchline || []

    this.meta = {
      title: data.data.meta_title || this.name,
      desc: data.data.meta_description || null,
    }

    this.dates = {
      start: data.data.startdate
        ? new Date(data.data.startdate.substring(0, 19).concat('.000Z'))
        : null,
      end: data.data.enddate
        ? new Date(data.data.enddate.substring(0, 19).concat('.000Z'))
        : null,
      teasing: data.data.teasing_startdate
        ? new Date(data.data.teasing_startdate.substring(0, 19).concat('.000Z'))
        : null,
    }

    this.teasingLink = data.data.teasing_link || null

    if (data.data.inventory_links && data.data.inventory_links.length > 0) {
      this.inventoryLinks = data.data.inventory_links
        .filter((l) => prismicH.isFilled.keyText(l.shopify_id))
        .map((l) => l.shopify_id)
    }

    if (data.data.current_batch_date_end) {
      this.dates.currentBatchEnd = new Date(
        data.data.current_batch_date_end.substring(0, 19).concat('.000Z')
      )
    }

    if (data.data.next_delivery_date) {
      this.dates.nextDeliveryDate = new Date(
        data.data.next_delivery_date.substring(0, 19).concat('.000Z')
      )
    }

    if (data.data.third_variant_name) {
      this.thirdDimensionName = data.data.third_variant_name || null
    }

    if (data.data.third_variant_label) {
      this.thirdDimensionLabel = data.data.third_variant_label || null
    }

    if (data.data.third_variant && data.data.third_variant.length > 0) {
      for (const v of data.data.third_variant) {
        if (v.name) {
          this.thirdDimensions.push({
            name: v.name,
            desc: v.desc || undefined,
            image: v.image || undefined,
            variantShopifyId: v.variant_shopify_id,
          })
        }
      }
    }

    this.thirdVariantEffect = data.data.third_variant_effect || false

    this.averageRating = data.data.average_rating
      ? parseFloat(data.data.average_rating)
      : 0
    this.numberOfTestimonials = data.data.testimonial_number
      ? parseInt(data.data.testimonial_number)
      : 0

    this.contentHidden = data.data.content_hidden || CONTENT_HIDDEN_LIST.FALSE
    this.marketing.type = data.data.marketing || MARKET_ARGUMENT.AUTOMATIQUE
    if (
      data.data.marketing_message &&
      prismicH.isFilled.richText(data.data.marketing_message)
    ) {
      this.marketing.message = prismicH.asText(data.data.marketing_message)
    }
    this.marketing.tag =
      data.data.marketing_tag || MARKET_ARGUMENT_TAG.AUTOMATIQUE
    this.buttonLabel = data.data.button_label || null

    // RETROCOMPATIBILITÉ
    if (!this.image || !this.image.url) {
      if (data.data.eshop_image) {
        this.image = data.data.eshop_image
      } else if (data.data.agenda_image) {
        this.image = data.data.agenda_image
      }
    }
    // END RETROCOMPATIBILITÉ

    this.cleanColors()
  }

  get saleable(): boolean {
    return this.status !== STATUS.AGENDA && this.status !== STATUS.TEASING
  }

  cleanColors() {
    const colors = []
    for (const color of this.colors) {
      if (Object.keys(color).length > 0 && color.color_name) {
        colors.push(color)
      }
    }

    this.colors = colors
  }

  /**
   * Retourne la liste des images éligibles à la galerie
   * @return un tableau d'image prismic
   */
  getImageGalleryFromSlices(sliders: any[]): IGallery[] {
    const gg: IGallery[] = []

    for (const slider of sliders) {
      const g = gg.find((g) => g.name === slider.color_name)
      if (g) {
        g.images = g.images.concat(slider.images)
      } else {
        gg.push({
          name: slider.color_name,
          images: slider.images,
          groups: [],
        })
      }
    }

    for (const slice of this.slices) {
      if (slice.slice_type === 'simple_content') {
        const g = gg.find((g) => g.name === 'default')
        let gallery: IGallery
        if (g) {
          gallery = g
        } else {
          gallery = { name: 'default', images: [], groups: [] }
          gg.push(gallery)
        }

        switch (slice.primary.image_format) {
          case IMAGE_FORMAT.SQUARE:
            gallery.images.push(slice.primary.image.square)
            break
          case IMAGE_FORMAT.LANDSCAPE:
            gallery.images.push(slice.primary.image.landscape)
            break
          default:
            gallery.images.push(slice.primary.image)
        }
      }

      if (slice.slice_type === 'morpho') {
        if (slice.items.length > 0) {
          const g = gg.find((g) => g.name === 'morpho')
          let gallery: IGallery
          if (g) {
            gallery = g
          } else {
            gallery = { name: 'morpho', images: [], groups: [] }
            gg.push(gallery)
          }
          for (const morpho of slice.items) {
            if (morpho.zoom_1.url) {
              gallery.images.push(morpho.zoom_1)
            }
            if (morpho.zoom_2.url) {
              gallery.images.push(morpho.zoom_2)
            }
            if (morpho.zoom_3.url) {
              gallery.images.push(morpho.zoom_3)
            }
            if (morpho.zoom_4.url) {
              gallery.images.push(morpho.zoom_4)
            }
          }
        }
      }
    }

    for (const g of gg) {
      g.groups = this.generateGalleryGroup(g)
    }

    return gg
  }

  generateGalleryGroup(gallery: IGallery) {
    const numberOfGroup = Math.ceil(gallery.images.length / 3)
    const groups: IGalleryGroup[] = []

    for (let i = 0; i < numberOfGroup; i++) {
      let groupOfImages = gallery.images.slice(i * 3, (i + 1) * 3)

      if (groupOfImages.some((i) => i.dimensions?.width === 480)) {
        groupOfImages = groupOfImages.sort((image) =>
          image.dimensions?.width === 480 ? -1 : 1
        )
        if (
          groupOfImages.filter((i) => i.dimensions?.width === 480).length === 2
        ) {
          groups.push({
            format: GROUPFORMAT.TWO_PORTRAIT,
            images: groupOfImages,
          })
        } else {
          groups.push({
            format: GROUPFORMAT.ONE_PORTRAIT,
            images: groupOfImages,
          })
        }
      } else if (groupOfImages.some((i) => i.dimensions?.width === 720)) {
        groups.push({
          format: GROUPFORMAT.ONE_SQUARE,
          images: groupOfImages,
        })
      } else {
        groups.push({
          format: GROUPFORMAT.DEFAULT,
          images: groupOfImages,
        })
      }
    }

    return groups
  }

  public toJSON() {
    return Object.assign({}, this)
  }

  static fromJSON(json: any) {
    const product = new PrismicProduct({
      id: '',
      uid: '',
      href: '',
      type: '',
      url: '',
      first_publication_date: '',
      last_publication_date: '',
      tags: [],
      lang: '',
      slugs: [],
      linked_documents: [],
      alternate_languages: [],
      data: {},
    })
    Object.assign(product, json)

    return product
  }
}
