<template>
  <slice
    class="slice--spacing"
    :class="[
      `spacing--${slice.primary.spacing_level.toLowerCase()}`,
      `spacing--desktop--${slice.primary.spacing_level_desktop.toLowerCase()}`,
    ]"
    :detect-in-screen="false"
  ></slice>
</template>

<script>
import Slice from '@/components/Slices/Slice'
export default {
  name: 'SpacingSlice',
  components: {
    Slice,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.spacing {
  @include mq($until: tablet) {
    &--simple {
      height: calc(var(--spacing) * 2);
    }

    &--double {
      height: calc(var(--spacing) * 4);
    }

    &--triple {
      height: calc(var(--spacing) * 6);
    }

    &--aucun {
      height: 0;
    }
  }

  @include mq($from: tablet) {
    &--desktop {
      &--simple {
        height: calc(var(--spacing) * 2);
      }

      &--double {
        height: calc(var(--spacing) * 4);
      }

      &--triple {
        height: calc(var(--spacing) * 6);
      }

      &--aucun {
        height: 0;
      }
    }
  }
}
</style>
