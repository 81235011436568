var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-card-v2",class:_vm.cardClass,on:{"mouseenter":function($event){_vm.$vssWidth > 769 ? (_vm.hovering = true) : false},"mouseleave":function($event){;(_vm.hovering = false), (_vm.activeColor = -1)}}},[_c(_vm.mainComponent(),{tag:"component",attrs:{"to":_vm.to},nativeOn:{"click":function($event){!_vm.isLink ? null : (_vm.loading = true)}}},[_c('div',{staticClass:"product-card-v2__image"},[_c('transition',{attrs:{"mode":"in-out","name":"product-card-image-switch"}},[(_vm.images.length > 0)?_c('lazy-image',{key:_vm.currentColor ? _vm.currentColor.color_name : 'default',attrs:{"images":_vm.images,"preload":_vm.isFirst,"force-show":_vm.isFirst}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"product-card-v2__content text-color-tertiary"},[_c('div',{staticClass:"inner"},[(_vm.home && !_vm.isSaisonnier)?_c('div',{staticClass:"d-none d-md-block"},[_c('p',{staticClass:"lh-1 text-md-base text-left mb-0-8"},[(
                _vm.prismicProduct.marketing.type === 'Texte' &&
                _vm.prismicProduct.marketing.message
              )?_c('span',[_vm._v("\n              "+_vm._s(_vm.prismicProduct.marketing.message)+"\n            ")]):_c('span',[_vm._v("\n              "+_vm._s(_vm.$t('preco_of_the_week'))+"\n            ")])])]):(_vm.isAgenda && _vm.isFirst)?_c('div',{staticClass:"d-none d-md-block"},[(_vm.prismicProduct.dates.start)?_c('p',{staticClass:"lh-1 text-md-base text-left mb-0-8"},[_vm._v("\n            "+_vm._s(_vm.$t('lauch_day', {
                day: _vm.$d(_vm.prismicProduct.dates.start, 'weekday'),
              }))+"\n          ")]):_vm._e()]):(
            (!_vm.isAgenda || _vm.prismicProduct.dates.start) && !_vm.stock && !_vm.isGiftCard
          )?_c('div',{staticClass:"d-flex mb-1",class:{
            'justify-center': !_vm.home && !_vm.isFirst,
          }},[_c('div',[_c('marketing-argument',{attrs:{"marketing":_vm.prismicProduct.marketing,"dates":_vm.prismicProduct.dates,"inventory":_vm.productInventory,"status":_vm.prismicProduct.status}})],1)]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"product-card-v2__name text-secondary mb-0-2",class:{
            'text-center': !_vm.home && !_vm.isFirst && !_vm.soonInSell && !_vm.stock,
          }},[_vm._v("\n          "+_vm._s(_vm.prismicProduct.name)+"\n        ")]),_vm._v(" "),(!_vm.home && !_vm.isAgenda && !_vm.isGiftCard)?_c('div',{staticClass:"product-card-v2__price d-flex flex-wrap align-baseline mb-1 mt-0-6"},[_c('div',{staticClass:"product-card__price d-flex align-center mb-0 flex-shrink-0",class:{ 'mr-1 mr-md-2': _vm.distinctColors.length > 1 }},[(!_vm.shopifyProduct)?_c('span',{staticClass:"d-block"},[_c('strong',{staticClass:"skeleton skeleton--bar d-inline-block mr-0-6",staticStyle:{"width":"30px","height":"16px"}}),_vm._v(" "),_c('strong',{staticClass:"skeleton skeleton--bar d-inline-block",staticStyle:{"width":"30px","height":"16px"}})]):_c('div',{staticClass:"product-card__price__inner d-block"},[_c('p',{staticClass:"price d-flex flex-wrap align-baseline text-color-tertiary text-secondary mb-0 mr-0-6 mr-md-1"},[_c('span',{staticClass:"number pr-0-4"},[_vm._v(_vm._s(_vm.shopifyProduct.price)+" €")]),_vm._v(" "),_c('span',{staticClass:"product-card__price__text text-small text-no-wrap"},[_vm._v(_vm._s(_vm.$t(
                      _vm.showCompareAtPrice
                        ? 'product.in_preco'
                        : 'product.in_stock'
                    )))])]),_vm._v(" "),(_vm.showCompareAtPrice)?_c('p',{staticClass:"price compare-at-price d-flex flex-wrap align-baseline text-color-tertiary text-secondary mb-0 mr-0-6 mr-md-1"},[_c('span',{staticClass:"number pr-0-4"},[_vm._v(_vm._s(_vm.shopifyProduct.compareAtPrice)+" €")]),_vm._v(" "),_c('span',{staticClass:"product-card__price__text text-small text-no-wrap"},[_vm._v(_vm._s(_vm.$t('product.in_stock')))])]):_vm._e()])]),_vm._v(" "),(_vm.distinctColors.length > 1)?_c('transition',{attrs:{"mode":"out-in"},on:{"enter":_vm.colorEnter,"leave":_vm.colorLeave}},[(_vm.hovering)?_c('div',{staticClass:"product-card__colors d-flex align-center flex-wrap"},_vm._l((_vm.distinctColors),function(color,index){return _c('button',{key:color.name,staticClass:"colors__matter",class:{ active: _vm.activeColor === index },attrs:{"type":"button","title":color.color_name},on:{"mouseenter":function($event){return _vm.colorMatterMouseEnter(index)},"click":function($event){$event.preventDefault();return _vm.goToColor(color.color_name)}}},[_c('span',{staticClass:"bg",style:(`background-image: url(${_vm.getColorThumbnailBg(
                    color
                  )})`)}),_vm._v(" "),_c('span',{staticClass:"visually-hidden"},[_vm._v(_vm._s(color.color_name))])])}),0):_c('p',{staticClass:"d-none d-md-block product-card__colors--number mb-0"},[(_vm.small)?_c('span',[_c('span',{staticClass:"d-inline d-md-none"},[_vm._v("\n                  "+_vm._s(_vm.$t('card.product.number-of-colors-small', {
                      number: _vm.distinctColors.length,
                    }))+"\n                ")]),_vm._v(" "),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("\n                  "+_vm._s(_vm.$t('card.product.number-of-colors', {
                      number: _vm.distinctColors.length,
                    }))+"\n                ")])]):_c('span',[_vm._v("\n                "+_vm._s(_vm.$t('card.product.number-of-colors', {
                    number: _vm.distinctColors.length,
                  }))+"\n              ")])])]):_vm._e()],1):(!_vm.isLink)?_c('div',{class:{
            'pb-0-4 mb-1': !_vm.isFirst,
            'w-100 pb-md-0-4 mb-0-4 mb-md-1': _vm.isFirst,
          }},[(_vm.isFirst)?_c('div',[_c('p',{staticClass:"d-none d-md-block lh-1-2 pb-md-0-4"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.$t('card.product.agenda.alert.1')))]),_vm._v(" "),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.$t('card.product.agenda.alert.2')))])]),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-center d-md-none"},[_c('p',{staticClass:"text-small lh-1-2 mb-0 pr-1 pt-0-4"},[_vm._v("\n                "+_vm._s(_vm.$tc(
                    'card.product.number-of-colors-soon',
                    _vm.distinctColors.length,
                    {
                      number: _vm.distinctColors.length,
                    }
                  ))+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-center pt-0-4"},[_c('button',{staticClass:"product-card-v2__agenda text-color-tertiary w-600",on:{"click":function($event){$event.preventDefault();return _vm.onAlertClick($event)}}},[_c('span',{staticClass:"d-flex align-center link--underline"},[_c('icon',{attrs:{"name":"bell","full":_vm.isSubscribed}}),_vm._v(" "),_c('span',{staticClass:"d-block ml-0-4"},[_vm._v(_vm._s(_vm.$t(
                        _vm.isSubscribed
                          ? 'alert.tooltip.remove'
                          : 'alert.tooltip.create'
                      )))])],1)])])]),_vm._v(" "),(_vm.$vssWidth > 796)?_c('div',{staticClass:"d-none d-md-block"},[_c('create-alert',{attrs:{"product":{
                  productSlug: _vm.prismicProduct.uid,
                  productName: _vm.prismicProduct.name,
                  productId: _vm.shopifyProduct.id,
                },"origin":_vm.origin,"button-size":"primary","inline":""},on:{"has-subscribe":function($event){return _vm.hasSubscribe()},"has-unsubscribe":function($event){return _vm.hasUnSubscribe()}}})],1):_vm._e()]):_c('div',[_c('p',{staticClass:"text-small text-center lh-1-2 pt-0-2"},[_vm._v("\n              "+_vm._s(_vm.$tc(
                  'card.product.number-of-colors-soon',
                  _vm.distinctColors.length,
                  {
                    number: _vm.distinctColors.length,
                  }
                ))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-center pt-0-4"},[_c('button',{staticClass:"product-card-v2__agenda text-color-tertiary w-600",on:{"click":function($event){$event.preventDefault();return _vm.onAlertClick($event)}}},[_c('span',{staticClass:"d-flex align-center link--underline"},[_c('icon',{attrs:{"name":"bell","full":_vm.isSubscribed}}),_vm._v(" "),_c('span',{staticClass:"d-block ml-0-4"},[_vm._v(_vm._s(_vm.$t(
                      _vm.isSubscribed
                        ? 'alert.tooltip.remove'
                        : 'alert.tooltip.create'
                    )))])],1)])])])]):_vm._e(),_vm._v(" "),(_vm.isGiftCard)?_c('div',[_c('p',{staticClass:"product-card__price d-flex justify-center align-center w-500 mb-0 flex-shrink-0"},[(!_vm.shopifyProduct)?_c('span',{staticClass:"d-block"},[_c('strong',{staticClass:"skeleton skeleton--bar d-inline-block mr-0-6",staticStyle:{"width":"30px","height":"16px"}}),_vm._v(" "),_c('strong',{staticClass:"skeleton skeleton--bar d-inline-block",staticStyle:{"width":"30px","height":"16px"}})]):_c('span',{staticClass:"d-block"},[_c('strong',[_vm._v(_vm._s(_vm.$t('product.price_range', {
                  min: _vm.shopifyProduct.priceRange.min,
                  max: _vm.shopifyProduct.priceRange.max,
                })))])])])]):_vm._e(),_vm._v(" "),(_vm.isLink && !_vm.stock && _vm.prismicProduct.buttonLabel)?_c('div',{class:{
            'd-flex mt-1 mb-0-6 justify-center': !_vm.isFirst,
            'd-none d-md-flex justify-start': _vm.isFirst,
            'mt-1 mb-0-6': _vm.isFirst,
            'd-none': !_vm.home,
          }},[_c('primary-button',{ref:"link",attrs:{"type":"button","text":_vm.prismicProduct.buttonLabel,"with-arrow":""}})],1):_vm._e()]),_vm._v(" "),((_vm.home || _vm.isAgenda) && _vm.isFirst && !_vm.isEternal)?_c('div',{staticClass:"product-card-v2__countdown d-flex d-md-block flex-direction-column align-center mt-1 mt-md-0"},[(_vm.home || _vm.isAgenda)?_c('div',{staticClass:"d-flex justify-center justify-md-end flex-grow-1 mb-0-6"},[(_vm.home)?_c('div',{staticClass:"tag tag--sun-and-white"},[_c('icon',{attrs:{"name":"estimated-time"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('product.tag.last_before_end')))])],1):_c('div',{staticClass:"tag tag--yellow-and-secondary"},[_c('icon',{attrs:{"name":"calendar-2"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('product.tag.soon_preco')))])],1)]):_vm._e(),_vm._v(" "),(_vm.home && _vm.prismicProduct.dates.end)?_c('big-count-down',{attrs:{"date":_vm.prismicProduct.dates.end,"home":""}}):_vm._e(),_vm._v(" "),(_vm.isAgenda && _vm.prismicProduct.dates.start)?_c('big-count-down',{attrs:{"date":_vm.prismicProduct.dates.start}}):_vm._e()],1):_vm._e()])]),_vm._v(" "),_c('transition',{attrs:{"name":"appear-top"}},[(_vm.subscribeError)?_c('p',{staticClass:"text-color-error text-small text-right"},[_vm._v("\n      "+_vm._s(_vm.$t('product.alert.error'))+"\n    ")]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"from-bottom"},on:{"after-enter":_vm.afterCreateAlertEnter}},[(_vm.isAlertOpen)?_c('create-alert',{attrs:{"id":`alert-${_vm.prismicProduct.uid}`,"product":{
        productSlug: _vm.prismicProduct.uid,
        productName: _vm.prismicProduct.name,
        productId: _vm.shopifyProduct.id,
      },"title":_vm.prismicProduct.dates.start !== null
          ? _vm.$t('product.alert.highlight_title', {
              name: _vm.prismicProduct.name,
              date: _vm.$d(_vm.prismicProduct.dates.start, 'short'),
            })
          : _vm.$t('product.back-one-day'),"text":_vm.$t(
          _vm.shopifyProduct.price === _vm.shopifyProduct.compareAtPrice
            ? 'product.receive-alert-stock'
            : 'product.receive-alert-preorder'
        ),"origin":_vm.origin,"button-size":"primary","with-close":""},on:{"has-subscribe":function($event){return _vm.hasSubscribe()},"has-unsubscribe":function($event){return _vm.hasUnSubscribe()},"close":function($event){_vm.isAlertOpen = false}}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"from-bottom"}},[(_vm.loading)?_c('div',{staticClass:"d-flex align-center justify-center pb-1 pt-1 text-color-tertiary text-secondary product-card-v2__loading"},[_c('span',[_vm._v(_vm._s(_vm.$t('loading')))]),_vm._v(" "),_c('icon-loading',{staticClass:"ml-1"})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }