<template>
  <div
    :class="{
      slices: !hasSpacingSlices,
      'slices--no-default-spacing': hasSpacingSlices,
    }"
  >
    <component
      :is="getComponent(slice.slice_type)"
      v-for="(slice, index) in definedAndVisibleSlices"
      :id="`${slice.slice_type}-${index}`"
      :key="`${slice.slice_type}-${index}`"
      :slice="slice"
      v-bind="getProps(slice)"
      :class="getClass(slice)"
      :first-slice="index === 0"
      :index="index"
      :slices-in-screen="slicesInScreen"
      :origin="origin"
      :gallery="gallery"
      :data-cy-slice="`${index}`"
      @in="inScreen(index)"
      @out="offScreen(index)"
    ></component>
  </div>
</template>

<script>
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'

import PrimaryButton from '@/components/buttons/Primary'
import LoadingSlice from '@/components/Slices/Loading'
import ProductListAutoSlice from '@/slices/ProductList/ProductListAuto'
import CTAImageSlice from '@/slices/CTAImage/CTAImageSlice'
import MultiCTAImage from '@/slices/MultiCTAImage/MultiCTAImage'
import BaniereSlice from '@/slices/Baniere/BaniereSlice'
import SpacingSlice from '@/slices/Spacing/SpacingSlice'

import { SLICE_PRODUCT_LIST, ORIGIN } from '@/const'
import replaceUids from '@/services/utils/replaceUids'

const SLICES = {
  cta_image: CTAImageSlice,

  liste_de_produit_automatique: ProductListAutoSlice,
  multi_cta_image: MultiCTAImage,
  baniere: BaniereSlice,

  espacement: SpacingSlice,

  double_produit_highlight: () => ({
    component: import(
      '@/slices/MultiCTAImage/DoubleProductHighlight' /* webpackChunkName: 'double_product_highlight' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),

  description: () => ({
    component: import(
      '@/slices/Description/Description' /* webpackChunkName: 'description_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),

  products: () => ({
    component: import(
      '@/slices/ProductList/ProductListSlice' /* webpackChunkName: 'product_list_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),

  articles: () => ({
    component: import(
      '@/slices/BlogList/BlogListSlice' /* webpackChunkName: 'blog_list_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  assurance: () => ({
    component: import(
      '@/slices/Assurance/AssuranceSlice' /* webpackChunkName: 'assurance_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  alert_subscribe: () => ({
    component: import(
      '@/slices/AlertSubscribe/AlertSubscribe' /* webpackChunkName: 'alert_subscribe_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  avis_client: () => ({
    component: import(
      '@/slices/Testimonials/TestimonialsSlice' /* webpackChunkName: 'testimonials_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  background_content: () => ({
    component: import(
      '@/slices/BackgroundContent/BackgroundContent' /* webpackChunkName: 'background_content_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  button_background: () => ({
    component: import(
      '@/slices/ButtonBackground/ButtonBackgroundSlice' /* webpackChunkName: 'button_background_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  button_black: () => ({
    component: import(
      '@/slices/ButtonBlack/ButtonBlackSlice' /* webpackChunkName: 'button_black_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  carrousel: () => ({
    component: import(
      '@/slices/Carousel/CarouselSlice' /* webpackChunkName: 'carousel_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  column_content: () => ({
    component: import(
      '@/slices/ColumnContent/ColumnContent' /* webpackChunkName: 'column_content_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  colors: () => ({
    component: import(
      '@/slices/Colors/ColorsSlice' /* webpackChunkName: 'colors_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  compteur: () => ({
    component: import(
      '@/slices/Countdown/CountdownSlice' /* webpackChunkName: 'countdown_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  credits: () => ({
    component: import(
      '@/slices/Credits/CreditsSlice' /* webpackChunkName: 'credits_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  delivery: () => ({
    component: import(
      '@/slices/Delivery/DeliverySlice' /* webpackChunkName: 'delivery_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  double_content: () => ({
    component: import(
      '@/slices/DoubleContent/DoubleContentSlice' /* webpackChunkName: 'double_content_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  gallerie_de_photo: () => ({
    component: import(
      '@/slices/Gallery/GallerySlice' /* webpackChunkName: 'gallery_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  iframe: () => ({
    component: import(
      '@/slices/Iframe/Iframe' /* webpackChunkName: 'iframe_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  image_full: () => ({
    component: import(
      '@/slices/ImageFull/ImageFull' /* webpackChunkName: 'image_full_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  issues: () => ({
    component: import(
      '@/slices/Issues/IssuesSlice' /* webpackChunkName: 'issues_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  impacts: () => ({
    component: import(
      '@/slices/RSE/RSEImpactSlice' /* webpackChunkName: 'rse_impact_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  media_content: () => ({
    component: import(
      '@/slices/MediaContent/MediaContent' /* webpackChunkName: 'media_content_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  onglet: () => ({
    component: import(
      '@/slices/Onglet/OngletSlice' /* webpackChunkName: 'onglet_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  simple_content: () => ({
    component: import(
      '@/slices/SimpleContent/SimpleContentSlice' /* webpackChunkName: 'simple_content_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  size_guide: () => ({
    component: import(
      '@/slices/SizeGuide/SizeGuideSlice' /* webpackChunkName: 'size_guide_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  tracabilite: () => ({
    component: import(
      '@/slices/RSE/RSETraceabilitySlice' /* webpackChunkName: 'res_traceability_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  our_mission: () => ({
    component: import(
      '@/slices/OurMission/OurMissionSlice' /* webpackChunkName: 'our_mission_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),

  title: () => ({
    component: import(
      '@/slices/TitleSlice/TitleSlice' /* webpackChunkName: 'title_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  titre: () => ({
    component: import(
      '@/slices/TitleSlice/TitleSlice' /* webpackChunkName: 'title_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),

  team: () => ({
    component: import(
      '@/slices/Team/TeamSlice' /* webpackChunkName: 'team_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),

  content: () => ({
    component: import(
      '@/slices/TexteSlice/TexteSlice' /* webpackChunkName: 'texte_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  texte: () => ({
    component: import(
      '@/slices/TexteSlice/TexteSlice' /* webpackChunkName: 'texte_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),

  reflexion_content: () => ({
    component: import(
      '@/slices/Reflexions/ReflexionsSlice' /* webpackChunkName: 'reflexions_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  video: () => ({
    component: import(
      '@/slices/Video/VideoSlice' /* webpackChunkName: 'video_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  waterfall_content: () => ({
    component: import(
      '@/slices/Waterfall/WaterfallSlice' /* webpackChunkName: 'waterfall_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  puzzle_content: () => ({
    component: import(
      '@/slices/Puzzle/PuzzleSlice' /* webpackChunkName: 'puzzle_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  table: () => ({
    component: import(
      '@/slices/Table/TableSlice' /* webpackChunkName: 'table_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  'portrait-size-guide-images': () => ({
    component: import(
      '@/slices/SizeGuideImages/SizeGuideImagesSlice' /* webpackChunkName: 'size_guide_images_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  'square-size-guide-images': () => ({
    component: import(
      '@/slices/SizeGuideImages/SizeGuideImagesSlice' /* webpackChunkName: 'size_guide_images_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  typeform: () => ({
    component: import(
      '@/slices/Typeform/TypeformSlice' /* webpackChunkName: 'typeform_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  questionnaires_list: () => ({
    component: import(
      '@/slices/QuestionnaireList/QuestionnaireList' /* webpackChunkName: 'questionnaire_list_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  morpho: () => ({
    component: import(
      '@/slices/Morpho/MorphoSlice' /* webpackChunkName: 'morpho_slice' */
    ),
    loading: LoadingSlice,
    delay: 0,
  }),
  // product_list_shopify_collection: () => ({
  //   component: import(
  //     '@/slices/ProductList/ProductListShopifyCollectionSlice' /* webpackChunkName: 'product_list_shopify_collection_slice' */
  //   ),
  //   loading: LoadingSlice,
  //   delay: 0,
  // }),

  barre_de_produits: () => ({
    component: import(
      '@/slices/ProductBar/ProductBar' /* webpackChunkName: 'product_bar' */
    ),
  }),

  quote: () => ({
    component: import('@/slices/Quote/Quote' /* webpackChunkName: 'quote' */),
  }),

  questionnaire_mis_en_avant: () => ({
    component: import(
      '@/slices/Survey/HighlightSurvey' /* webpackChunkName: 'highlight_survey' */
    ),
  }),

  product_nav: () => ({
    component: import(
      '@/components/Slices/Nav' /* webpackChunkName: 'slice_nav' */
    ),
  }),

  user_generated_content: () => ({
    component: import(
      '@/slices/UserGeneratedContent/UserGeneratedContentSlice' /* webpackChunkName: 'user_generated_content' */
    ),
  }),
}

export default {
  name: 'Slices',
  components: {
    PrimaryButton,
    CreateAlert: () => import('@/components/Product/CreateAlert'),
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    entities: {
      type: Object,
      default: () => {},
    },
    slices: {
      type: Array,
      required: true,
    },
    nav: {
      type: Boolean,
      default: false,
    },
    activateScrollBtn: {
      type: Boolean,
      default: false,
    },
    gallery: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      required: true,
      validator: (value) => {
        return Object.values(ORIGIN).includes(value)
      },
    },
  },
  data: () => ({
    slicesInScreen: [],
    ORIGIN,
  }),
  computed: {
    definedAndVisibleSlices() {
      const ss = this.slices.filter((slice) => {
        return (
          slice.slice_type in SLICES &&
          slice.slice_label !== 'hidden' &&
          slice.primary.visibility !== false
        )
      })

      if (this.nav) {
        ss.splice(0, 0, { id: 'product_nav-1', slice_type: 'product_nav' })
      }

      return ss
    },
    hasSpacingSlices() {
      return this.definedAndVisibleSlices.some(
        (slice) => slice.slice_type === 'espacement'
      )
    },
    bisProduct() {
      if (this.entities.prismicDocument && this.entities.shopifyProduct) {
        return {
          productSlug: this.entities.prismicDocument.uid,
          productName: this.entities.prismicDocument.name,
          productId: this.entities.shopifyProduct.id,
        }
      }

      return {}
    },
  },
  methods: {
    getComponent(type) {
      return SLICES[type]
    },
    getProps(slice) {
      if (slice.slice_type === 'product_nav') {
        return {
          slices: this.definedAndVisibleSlices,
          slicesInScreen: this.slicesInScreen,
          prismicProduct: this.entities.prismicDocument,
          teasing: this.teasing,
        }
      }

      if (this.entities) {
        if (SLICE_PRODUCT_LIST.includes(slice.slice_type)) {
          return { products: this.entities.products }
        }

        if (slice.slice_type === 'avis_client') {
          return {
            uid: replaceUids.replaceAlterWithOrigin(
              this.entities.prismicDocument.uid
            ),
            average: this.entities.prismicDocument.averageRating,
          }
        }

        if (slice.slice_type === 'table' && this.entities.activeSize) {
          return {
            activeCol: this.entities.activeSize,
          }
        }

        if (
          slice.slice_type === 'alert_subscribe' &&
          this.entities.linkedProduct
        ) {
          return {
            linkedProduct: this.entities.linkedProduct,
            linkedShopifyProduct: this.entities.linkedShopifyProduct,
          }
        }

        if (
          slice.slice_type === 'questionnaires_list' &&
          this.entities.questionnaires
        ) {
          return {
            questionnaires: this.entities.questionnaires,
          }
        }

        if (
          slice.slice_type === 'barre_de_produits' &&
          this.entities.collection
        ) {
          return {
            collection: this.entities.collection,
            products: this.entities.products,
          }
        }
      }

      return {}
    },
    getClass(slice) {
      const c = []

      if (slice.slice_label) {
        c.push(slice.slice_label)
      }

      return c
    },
    inScreen(index) {
      if (!this.slicesInScreen.includes(index)) {
        this.slicesInScreen.push(index)
      }
    },
    offScreen(index) {
      const i = this.slicesInScreen.indexOf(index)
      this.slicesInScreen.splice(i, 1)
    },
    scrollDown(ev) {
      ev.preventDefault()

      this.$track.click('scroll_down')

      ev.target.blur()

      return false
    },
  },
}
</script>

<style lang="scss">
.slices {
  > .slice,
  > .slice.skeleton {
    margin-bottom: calc(var(--spacing) * 3);

    @include mq($from: tablet) {
      margin-bottom: calc(var(--spacing) * 6);
    }
  }

  > .slice {
    &:first-child {
      margin-top: rem($spacing * 2);

      @include mq($from: tablet) {
        margin-top: rem($spacing * 6);
      }

      &.slice--baniere,
      &.slice--products {
        margin-top: 0;
      }
    }

    &:last-child {
      &.slice--products--grid,
      &.slice--multi-cta-image {
        margin-bottom: 0;
      }
    }
  }

  .slice.skeleton,
  .slice--purchase,
  .slice--background-content,
  .slice--media-content,
  .slice--nav,
  .slice--typeform {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .slice--our-mission {
    margin-bottom: rem($spacing * 4);

    @include mq($from: tablet) {
      margin-top: rem($spacing * 10);
      margin-bottom: rem($spacing * 10);
    }
  }

  .slice--collection-list {
    + .slice--description.only-button {
      margin-top: rem(-$spacing);

      @include mq($from: tablet) {
        margin-top: rem(-$spacing * 6);
      }
    }
  }

  .slice--products {
    + .slice--products {
      margin-top: calc(var(--spacing) * -3);

      @include mq($from: tablet) {
        margin-top: calc(var(--spacing) * -6);
      }

      &.with-title {
        margin-top: 0;

        @include mq($from: tablet) {
          margin-top: 0;
        }
      }
    }

    + .slice--quote {
      margin-top: calc(var(--spacing) * -2);

      @include mq($from: tablet) {
        margin-top: calc(var(--spacing) * -5);
      }
    }

    &.full {
      + .slice--products {
        margin-top: 0;
      }
    }
  }

  .slice--products-two-cols {
    + .slice--products {
      margin-top: calc(var(--spacing) * -3);
    }
  }

  .slice--title {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 0;
    }

    + .slice--simple-content {
      margin-top: calc(var(--spacing) * -1);

      @include mq($from: tablet) {
        margin-top: calc(var(--spacing) * -2);
      }
    }

    + .slice--texte,
    + .slice--image-full {
      margin-top: calc(var(--spacing) * -2);

      @include mq($from: tablet) {
        margin-top: calc(var(--spacing) * -4);
      }
    }
  }

  .slice--simple-content {
    + .slice--traceability {
      @include mq($from: tablet) {
        margin-top: rem($spacing * 8);
      }
    }
  }

  .slice--video {
    @include mq($from: tablet) {
      margin-bottom: rem($spacing * 8);
    }
  }

  .slice--purchase,
  .slice--issues {
    margin-bottom: rem($spacing * 4);

    @include mq($from: tablet) {
      margin-bottom: rem($spacing * 12);
    }
  }

  .slice--purchase {
    + .slice--issues,
    + .slice--nav {
      margin-top: rem(-$spacing * 4);

      @include mq($from: tablet) {
        margin-top: rem(-$spacing * 12);
      }
    }
  }

  .slice--traceability,
  .slice--impacts {
    margin-bottom: rem($spacing * 4);

    @include mq($from: tablet) {
      margin-bottom: rem($spacing * 8);
    }
  }

  .slice--background-content {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .slice--description {
    + .slice--collection-list,
    + .slice--product-bar {
      margin-top: rem(-$spacing);

      @include mq($from: tablet) {
        margin-top: rem(-$spacing * 2);
      }
    }

    + .slice--product-list {
      margin-top: rem(-$spacing * 2);

      @include mq($from: tablet) {
        margin-top: rem(-$spacing * 3);
      }
    }
  }

  .slice--product-bar {
    + .slice--products {
      margin-top: calc(var(--spacing) * -2);

      @include mq($from: tablet) {
        margin-top: calc(var(--spacing) * -4);
      }
    }
  }

  .slice--button-background {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .slice--size-guide {
    margin-bottom: rem($spacing * 3);

    @include mq($from: tablet) {
      margin-top: rem(-$spacing * 3);
    }
  }

  .slice--texte {
    + .slice--image-full {
      margin-top: rem(-$spacing * 2);

      @include mq($from: tablet) {
        margin-top: rem(-$spacing * 4);
      }
    }

    + .slice--countdown {
      margin-top: rem(-$spacing * 2);

      @include mq($from: tablet) {
        margin-top: rem(-$spacing * 4);
      }
    }
  }

  .slice--countdown {
    + .slice--texte {
      margin-top: rem(-$spacing * 2);

      @include mq($from: tablet) {
        margin-top: rem(-$spacing * 4);
      }
    }

    + .slice--products--auto.slice--products--grid {
      margin-top: rem(-$spacing * 2.8);
    }
  }

  .slice--image-full {
    + .slice--texte {
      margin-top: rem(-$spacing * 2);

      @include mq($from: tablet) {
        margin-top: rem(-$spacing * 4);
      }
    }

    + .slice--image-full {
      margin-top: rem(-$spacing * 2);

      @include mq($from: tablet) {
        margin-top: rem(-$spacing * 4);
      }

      &.image-height-auto {
        margin-top: rem(-$spacing * 3);

        @include mq($from: tablet) {
          margin-top: rem(-$spacing * 6);
        }
      }
    }
  }

  .slice--baniere {
    margin-bottom: rem($spacing * 3);

    + .slice--countdown {
      margin-top: rem(-$spacing * 2);
    }
  }

  .slice--products--auto.slice--products--grid + .slice--baniere {
    margin-top: calc(var(--spacing) * -2.6);

    @include mq($from: tablet) {
      margin-top: calc(var(--spacing) * -5.6);
    }
  }

  .slice--gallery {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 0;

    &__item {
      position: relative;
      overflow: hidden;
      transform: translateY(10);
      opacity: 0;
      transition: all 0.5s var(--asphalte-animation-function);
      grid-column: var(--grid-column-mobile);
      grid-row: var(--grid-row-mobile);

      @include mq($from: tablet) {
        grid-column: var(--grid-column);
        grid-row: var(--grid-row);
      }

      &.is-visible {
        transform: translateY(0);
        opacity: 1;
      }
    }

    + .slice--alert-subscribe.side-by-side {
      @include mq($until: tablet) {
        margin-top: calc(var(--spacing) * -3);
      }
    }
  }

  .slice--alert-subscribe.side-by-side {
    + .slice--gallery {
      @include mq($until: tablet) {
        margin-top: calc(var(--spacing) * -3);
      }
    }
  }

  &__create-alert {
    position: absolute;
    left: 50%;
    z-index: 3;
    bottom: 20%;
    transform: translateX(-50%);
    max-width: rem(510px);
    width: 100%;
    text-align: center;
    padding: 0 var(--spacing);

    .icon {
      margin: 0 auto calc(var(--spacing) * 2);
      color: var(--secondary-color);

      @include mq($from: tablet) {
        margin: 0 auto calc(var(--spacing) * 3);
      }
    }

    .inner {
      padding: calc(var(--spacing) * 2);
      background: var(--secondary-color);
      color: var(--tertiary-color);
    }

    .create-alert__title {
      font-size: var(--h4-font-size);
    }

    form {
      max-width: rem(360px);
      margin: 0 auto;
    }

    .input__line {
      background: $yellow;
      height: rem(3px);
      max-width: calc(100% - #{rem(1px)});
    }

    .action {
      width: 100%;
      margin-top: rem($spacing);

      @include mq($from: tablet) {
        height: rem(45px);
        padding: 0;
      }
    }
  }
}
</style>
