<template>
  <div
    :class="cardClass"
    :data-id="prismicProduct.shopifyId"
    data-cy-product-card
    @mouseenter="hovering = true"
    @mouseleave=";(hovering = false), (activeColor = -1)"
  >
    <div class="product-card__image">
      <nuxt-link
        :to="link"
        :href="link.path"
        prefetch
        @click.native="loading = true"
      >
        <lazy-image
          v-if="hasImage"
          :image="prismicProduct.image"
          :sizes="`${prismicProduct.image.dimensions.width}px`"
        />

        <div
          v-if="
            productStatus === STATUS.AGENDA || productStatus === STATUS.TEASING
          "
          class="product-card__date mb-0"
        >
          <p v-if="prismicProduct.dates.start !== null">
            <span
              v-if="
                prismicProduct.marketing.type === MARKET_ARGUMENT.TEXTE &&
                prismicProduct.marketing.message
              "
              class="h6 d-block text-secondary mb-0"
            >
              {{ prismicProduct.marketing.message }}
            </span>
            <span v-else class="h6 text-secondary mb-0">
              {{ $d(prismicProduct.dates.start, 'short') }}
            </span>
          </p>
          <p v-else class="product-card__date text-small mb-0">
            {{ $t('card.product.agenda.status') }}
          </p>
        </div>
      </nuxt-link>

      <transition name="from-bottom">
        <create-alert
          v-if="isAlertOpen"
          :product="{
            productSlug: prismicProduct.uid,
            productName: prismicProduct.name,
            productId: prismicProduct.shopifyId,
          }"
          :title="
            prismicProduct.dates.start !== null
              ? $d(prismicProduct.dates.start, 'short')
              : $t('product.back-one-day')
          "
          :text="
            $t(
              willBeInStock
                ? 'product.receive-alert-stock'
                : 'product.receive-alert-preorder'
            )
          "
          :origin="origin"
          button-size="secondary"
          @has-subscribe="hasSubscribe()"
          @has-unsubscribe="hasUnSubscribe()"
        />
      </transition>

      <transition name="from-bottom">
        <div
          v-if="loading"
          class="
            d-flex
            align-center
            justify-center
            pb-1
            pt-1
            text-color-tertiary text-secondary
            product-card__loading
          "
        >
          <span>{{ $t('loading') }}</span>
          <icon-loading class="ml-1" />
        </div>
      </transition>
    </div>

    <div class="d-flex justify-space-between align-start">
      <nuxt-link
        ref="link"
        :to="link"
        :href="link.path"
        class="flex-grow-1 mx-w-100"
        data-cy-product-card-link
        @click.native="loading = true"
      >
        <p class="product-card__name mb-0">
          <strong class="w-500">{{ prismicProduct.name }}</strong>
        </p>

        <div class="d-flex align-center justify-space-between">
          <p
            v-if="
              (productStatus !== STATUS.AGENDA &&
                productStatus !== STATUS.TEASING) ||
              prismicProduct.dates.start !== null
            "
            class="product-card__price d-flex align-center w-500 mb-0"
          >
            <span v-if="loadingShopifyProduct">
              <strong
                class="skeleton skeleton--bar d-inline-block mr-0-6"
                style="width: 30px; height: 16px"
              ></strong>
              <strong
                class="skeleton skeleton--bar d-inline-block"
                style="width: 30px; height: 16px"
              ></strong>
            </span>
            <span v-else>
              <strong v-if="!willBeInStock" class="compare-at-price mr-0-6"
                ><span>{{ shopifyProduct.compareAtPrice }}</span
                >&nbsp;&euro;</strong
              >
              <strong>{{ shopifyProduct.price }}&nbsp;&euro;</strong>
            </span>
          </p>

          <transition
            v-if="distinctColors.length > 1 && hoverColorActivate"
            mode="out-in"
            @enter="colorEnter"
            @leave="colorLeave"
          >
            <p
              v-if="!hovering"
              class="
                product-card__colors--number
                text-extra-small text-color-secondary-2
                mt-0-2
                mb-0
                d-none d-md-block
              "
            >
              {{
                $t('card.product.number-of-colors', {
                  number: distinctColors.length,
                })
              }}
            </p>
          </transition>
        </div>

        <client-only v-if="hoverColorActivate">
          <transition
            v-if="distinctColors.length > 1"
            mode="out-in"
            @enter="colorEnter"
            @leave="colorLeave"
          >
            <div
              v-if="hovering || $vssWidth < 769"
              class="
                product-card__colors
                d-flex
                align-center
                flex-wrap
                mt-0-4 mt-md-0
              "
              @click.prevent=""
            >
              <button
                v-for="(color, index) in distinctColors.slice(0, maxColor)"
                :key="color.name"
                type="button"
                class="colors__matter"
                :class="{ active: activeColor === index }"
                :title="color.color_name"
                @mouseenter="activeColor = index"
                @touchstart.prevent=";(activeColor = index), (hovering = true)"
                @click.prevent="goTo(index)"
              >
                <span
                  class="bg"
                  :style="`background-image: url(${color.color_thumbnail.url})`"
                ></span>
                <span class="visually-hidden">{{ color.color_name }}</span>
              </button>

              <button
                v-if="distinctColors.length > maxColor"
                type="button"
                class="colors__matter colors__matter--more"
              >
                <span class="line"></span>
                <span class="line"></span>
              </button>
            </div>
          </transition>
        </client-only>
      </nuxt-link>

      <a
        v-if="
          productStatus === STATUS.AGENDA || productStatus === STATUS.TEASING
        "
        ref="subscribeButton"
        class="product-card__subscribe link--underline"
        :class="
          isSubscribed ? 'text-color-secondary' : 'text-color-secondary-2'
        "
        @click.prevent="onAlertClick($event)"
      >
        <icon name="bell" :full="isSubscribed" />
        <span class="text-color-secondary text-uppercase mt-0-6">{{
          $t(isSubscribed ? 'alert.tooltip.remove' : 'alert.tooltip.create')
        }}</span>
      </a>
    </div>

    <transition name="appear-top">
      <p v-if="subscribeError" class="text-color-error text-small text-right">
        {{ $t('product.alert.error') }}
      </p>
    </transition>
  </div>
</template>

<script>
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'
import { mapGetters, mapActions } from 'vuex'
import * as prismicH from '@prismicio/helpers'

import LazyImage from '@/components/Image/LazyImage'
// import ImageSimple from '@/components/Image/ImageSimple'
import Icon from '@/components/icons/Icon'
import IconLoading from '@/components/icons/Loading'

import Product from '@/entities/shopify/Product'

import { ORIGIN, STATUS, MARKET_ARGUMENT } from '@/const'
import { EVENTS } from '@/const/events'
import normalize from '@/utils/normalize'

export default {
  name: 'ProductCard',
  components: {
    LazyImage,
    // ImageSimple,
    Icon,
    IconLoading,
    CreateAlert: () =>
      import(
        '@/components/Product/CreateAlert' /* webpackChunkName: 'product_create_alert' */
      ),
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    prismicProduct: {
      type: Object,
      required: true,
    },
    shopifyProduct: {
      type: Object,
      required: true,
    },
    /**
     * Où est placé card
     */
    origin: {
      type: String,
      default: ORIGIN.AGENDA,
      validator: (value) => {
        return Object.values(ORIGIN).includes(value)
      },
    },
    /**
     * Le produit shopify est-il chargé ?
     */
    loadingShopifyProduct: { type: Boolean, default: false },
    /**
     * La carte est-elle affichée en état de chargement ?
     */
    skeleton: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      isAlertOpen: false,
      isSubscribed: false,
      subscribeError: false,
      hovering: false,
      activeColor: -1,
      ORIGIN,
      STATUS,
      MARKET_ARGUMENT,
    }
  },
  computed: {
    ...mapGetters({
      connectedEmail: 'subscriptions/connectedEmail',
      account: 'subscriptions/account',
      isSubscriptionsLoading: 'subscriptions/isLoading',
    }),
    hasImage() {
      return prismicH.isFilled.image(this.prismicProduct.image)
    },
    productStatus() {
      if (this.shopifyProduct.isSaleableAsStock) {
        return STATUS.STOCK
      }

      return this.prismicProduct.status
    },
    willBeInStock() {
      if (this.shopifyProduct) {
        return Product.willBeInStock(this.shopifyProduct)
      }

      return false
    },
    hoverColorActivate() {
      return (
        this.productStatus !== STATUS.AGENDA &&
        this.productStatus !== STATUS.TEASING
      )
    },
    cardClass() {
      const cc = [
        'product-card',
        'product-card--default',
        `product-card--${this.productStatus}`,
      ]

      if (this.isAlertOpen) {
        cc.push('product-card--with-alert')
      }

      return cc
    },
    link() {
      if (this.skeleton) return ''

      let q

      if (
        this.activeColor > -1 &&
        this.prismicProduct.colors[this.activeColor] &&
        this.prismicProduct.colors[this.activeColor].color_name
      ) {
        q = this.prismicProduct.colors[this.activeColor].color_name
      }

      return {
        path: this.$contextPrismicPath({
          type: 'product',
          uid: this.prismicProduct.uid,
        }),
        query: q,
      }
    },
    distinctColors() {
      if (this.prismicProduct.thirdDimensionName === null) {
        return this.prismicProduct.colors
      }

      const cc = []

      for (const color of this.prismicProduct.colors) {
        if (!cc.some((c) => c.color_name === color.color_name)) {
          cc.push(color)
        }
      }

      return cc
    },
    maxColor() {
      if (this.$vssWidth < 330) {
        return 6
      }
      if (this.$vssWidth < 370) {
        return 7
      }

      return 8
    },
  },
  beforeDestroy() {
    this.$nuxt.$off(EVENTS.USER_STATE_LOADED)
  },
  mounted() {
    if (
      this.connectedEmail === null ||
      (this.connectedEmail !== '' && this.account.email === null)
    ) {
      this.$nuxt.$on(EVENTS.USER_STATE_LOADED, (_) => {
        this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)
      })
    } else {
      this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)
    }
  },
  methods: {
    ...mapActions({
      subscribe: 'subscriptions/subscribe',
      unsubscribe: 'subscriptions/unsubscribe',
    }),

    onAlertClick(ev) {
      ev.stopPropagation()

      const product = {
        productSlug: this.prismicProduct.uid,
        productName: this.prismicProduct.name,
        productId: this.prismicProduct.shopifyId,
        productImage: 'https://',
        location: this.origin,
      }

      if (this.isSubscribed) {
        return this.unsubscribe(product)
          .then(() => {
            this.isSubscribed = this.account.isSubscribed(
              this.prismicProduct.uid
            )

            this.$track.bisUnSubscribe(this.prismicProduct.uid, this.origin)

            this.showToaster(`<div>
                ${this.$t('product.alert.deactivated', {
                  produit: this.prismicProduct.name,
                })}
              </div>
              `)
          })
          .catch((err) => {
            this.$logError.captureException(err)
            this.subscribeError = true
          })
      }

      if (this.connectedEmail === '') {
        if (!this.isAlertOpen) {
          const productWithAlert = document.getElementsByClassName(
            'product-card--with-alert'
          )

          if (productWithAlert.length > 0) {
            for (let i = 0; i < productWithAlert.length; i++) {
              productWithAlert[i].__vue__.isAlertOpen = false
            }
          }
        }

        this.isAlertOpen = !this.isAlertOpen

        return
      }

      return this.subscribe(product)
        .then(() => {
          this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)

          this.$nuxt.$emit(EVENTS.ASK_NOTIFICATION, this.origin)
          this.$track.bisSubscribe(
            this.account.email,
            this.prismicProduct.uid,
            this.origin
          )

          this.showToaster(`<div>
                ${this.$t('product.alert.activated', {
                  produit: this.prismicProduct.name,
                  email: this.account.email,
                })}
              </div>
              `)
        })
        .catch((err) => {
          this.$logError.captureException(err)
          this.subscribeError = true
        })
    },
    showToaster(text) {
      this.$toasted.clear()
      this.$toasted.show(text, {
        position: 'bottom-center',
        duration: 5000,
        keepOnHover: true,
        action: {
          text: '',
          icon: 'close',
          class: 'toasted__close',
          onClick: (_, toastObject) => {
            toastObject.goAway(0)
          },
        },
      })
    },
    hasSubscribe() {
      this.isAlertOpen = false
      this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)
    },
    hasUnSubscribe() {
      this.isAlertOpen = false
      this.isSubscribed = this.account.isSubscribed(this.prismicProduct.uid)
    },
    goTo(index) {
      this.$router.push({
        path: this.$contextPrismicPath({
          type: 'product',
          uid: this.prismicProduct.uid,
        }),
        query: {
          color: normalize(this.prismicProduct.colors[index].color_name),
        },
      })
    },
    async colorEnter(el, done) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      if (el.classList.contains('product-card__colors')) {
        const buttons = el.getElementsByClassName('colors__matter')

        gsap.from(buttons, {
          opacity: 0,
          x: 20,
          stagger: {
            ease: 'power1.out',
            amount: 0.2,
          },
          duration: 0.4,
          onComplete() {
            return done()
          },
        })
      } else {
        return gsap.from(el, {
          opacity: 0,
          duration: 0.3,
          onComplete() {
            return done()
          },
        })
      }
    },
    async colorLeave(el, done) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      return gsap.to(el, {
        opacity: 0,
        duration: 0.3,
        onComplete() {
          return done()
        },
      })
    },
  },
}
</script>
