export const productGraphql = `{
    product {
      product_name
      product_type
      market_product_type
      shopify_id
      status
      stock_initial
      image
      image_cdn
      image_eshop
      image_text_position
      image_text_align
      image_eshop_text_position
      is_top_of_image_light
      startdate
      enddate
      content_hidden
      marketing
      marketing_message
      marketing_tag
      button_label
      third_variant_name
      context
      teasing_startdate
      teasing_link
      on_home
      colors {
        image_principale
        image_principale_cdn
        color_name
        color_thumbnail
        color_thumbnail_cdn
        third_variant_name
        is_leaving_soon
        is_new
      }
      linked_products {
        label
        image
        product_option
      }
      inventory_links {
        shopify_id
      }
      agenda_image
    }
  }`

export const productGraphqlRefCo = `{
    product {
        product_name
        shopify_id
        market_product_type
        image
        image_cdn
        image_eshop
        image_eshop_cdn
        is_top_of_image_light
        button_label
        third_variant_name
        punchline
        enddate
        current_batch_date_end
        next_delivery_date
        colors {
            image_principale
            image_principale_cdn
            color_name
            third_variant_name
            is_leaving_soon
            is_new
        }
        linked_products {
          label
          product {
            ...on product {
              shopify_id
              colors {
                image_principale
                color_name
                third_variant_name
              }
            }
          }
        }
        ref_co_matrix {
            number_per_line
        }
        ref_co_matrix_mobile {
            number_per_line
        }
        order_and_render_of_ref_co {
            color_name
            from_linked_product
            linked_product_label
            override_image
            override_image_cdn
            is_edito
            edito
            edito_color
            is_video
            video_id
        }
    }
}`
