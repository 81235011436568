<template>
  <slice
    class="slice--multi-cta-image slice--products"
    :class="{ 'pb-2 pb-md-4': !slice.primary.is_full }"
  >
    <c-t-a-images
      :items="itemsToShow"
      :is-full="slice.primary.is_full"
      :loading="loading.init || loadingCollection"
      :loading-shopify="loading.shopify"
      :one-title="slice.primary.one_title"
      :text="slice.primary.text"
      :title="slice.primary.title"
      :button-label="slice.primary.button_label"
    />
  </slice>
</template>

<script>
import * as prismic from '@prismicio/client'
import * as prismicH from '@prismicio/helpers'

import Slice from '@/components/Slices/Slice'
import CTAImages from '@/slices/MultiCTAImage/CTAImages'

import ProductListMixin from '@/mixins/productList'

import replaceUids from '@/services/utils/replaceUids'

export default {
  name: 'MultiCTAImage',
  components: {
    Slice,
    CTAImages,
  },
  mixins: [ProductListMixin],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loadingCollection: true,
    collections: [],
  }),
  async fetch() {
    await ProductListMixin.fetch.apply(this)

    try {
      this.collections = await this.$prismic.client
        .get({
          predicates: prismic.predicate.in(
            'my.collection.uid',
            this.collectionUids
          ),
          graphQuery: `{
              collection {
                collection_name
                image
              }
            }`,
          lang: this.$i18n.locale,
        })
        .then((response) => {
          return response.results
        })

      this.loadingCollection = false
    } catch (error) {
      this.$logError.captureException(error)
    }
  },
  computed: {
    items() {
      return this.slice.items.slice(0, 3)
    },
    productsItems() {
      return this.items
        .filter((item) => item.link.type === 'product')
        .map((item) => ({
          product: item.link,
        }))
    },
    productUids() {
      return [
        ...new Set(
          this.items
            .filter((item) => item.link.type === 'product')
            .map((item) => item.link.uid)
        ),
      ]
    },
    collectionUids() {
      return [
        ...new Set(
          this.items
            .filter((item) => item.link.type === 'collection')
            .map((item) => replaceUids.replaceOriginWithAlter(item.link.uid))
        ),
      ]
    },
    itemsToShow() {
      return this.items
        .map((item) => {
          if (item.link.type === 'product') {
            const product = this.productList.find(
              (p) =>
                p.prismicProduct.uid ===
                replaceUids.replaceOriginWithAlter(item.link.uid)
            )
            if (product) {
              return {
                ...product.prismicProduct,
                shopifyProduct: product.shopifyProduct,
                name:
                  item.title !== null
                    ? item.title
                    : product.prismicProduct.name,
                type: item.link.type,
              }
            }
          }

          if (item.link.type === 'collection') {
            const collection = this.collections.find(
              (c) => c.uid === replaceUids.replaceOriginWithAlter(item.link.uid)
            )
            if (collection) {
              return {
                uid: collection.uid,
                name:
                  item.title !== null
                    ? item.title
                    : prismicH.asText(collection.data.collection_name),
                text: item.text,
                image: collection.data.image,
                type: item.link.type,
                anchor: item.anchor || null,
              }
            }
          }

          return null
        })
        .filter((i) => i !== null)
    },
  },
}
</script>
